// Module Imports
import React, { useState } from "react"
import { Link } from "gatsby"
import { Link as ScrollLink } from "react-scroll"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Spin as Burger } from "react-burgers"
// Custom Imports
import { Container } from "../layout"
import { Bold } from "../elements"
import { spacing, colors } from "../designsystem"
import LOGO from "../images/logo.svg"

// Component
const Nav = ({ className, menu, dontRenderMainMenu }) => {
  const [navIsActive, setNavIsActive] = useState(false)

  const BurgerAriaProps = {
    "aria-label": "Menü",
    "aria-controls": "scroll-navigation",
  }

  return (
    <div className={className}>
      <nav className={navIsActive ? "navIsActive" : ""}>
        <Container>
          <Link to="/">
            <img src={LOGO} alt="Förderverein MTS" />
          </Link>

          {!dontRenderMainMenu && (
            <>
              <Burger
                onClick={() => setNavIsActive(!navIsActive)}
                active={navIsActive}
                padding={0}
                color={colors.primary.base}
                ariaLabel="Menu"
                customProps={BurgerAriaProps}
              />
              <ul id="scroll-navigation" className="clean">
                {menu.map((menuItem, menuItemKey) => (
                  <li key={menuItemKey}>
                    <ScrollLink spy smooth to={menuItem.scrollTo} offset={-64}>
                      {menuItem.title}
                    </ScrollLink>
                  </li>
                ))}
              </ul>
            </>
          )}
        </Container>
      </nav>
    </div>
  )
}

// PropTypes
Nav.propTypes = {
  className: PropTypes.string,
  menu: PropTypes.array,
  dontRenderMainMenu: PropTypes.bool,
}

// Export default as styled component
export default styled(Nav)`
  padding-bottom: 80px;

  nav {
    position: fixed;
    background-color: ${colors.defaults.white};
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    padding: ${spacing[2]} 0;
    border-bottom: 1px solid ${colors.grey.semilight};
    ${Container} {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    img {
      width: 75px;
      height: 48px;
    }

    button {
      position: fixed;
      top: 30px;
      z-index: 11;
      right: ${spacing[3]};
    }

    ul {
      padding-top: ${spacing[4]};
      position: fixed;
      background-color: ${colors.defaults.white};
      height: 100%;
      min-width: 50vw;
      top: 0;
      right: 0;
      text-align: center;
      transform: translateX(100%);
      transition: transform 300ms ease-out;
      li {
        margin: ${spacing[3]} 0;
        color: ${colors.primary.dark};
      }

      a {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &.navIsActive ul {
      transform: translateX(0);
    }
  }

  @media screen and (min-width: 690px) {
    nav {
      button {
        display: none;
      }
      ul {
        background-color: transparent;
        padding-top: 0;
        transform: translateX(0);
        text-align: left;
        height: auto;
        min-width: 0;
        position: static;
        li {
          display: inline;
          &:not(:last-child) {
            margin-right: ${spacing[2]};
          }
        }
      }
    }
  }
`
