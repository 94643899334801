// Module Imports
import PropTypes from "prop-types"
import styled from "styled-components"
// Custom Imports
import { colors, spacing } from "../designsystem"

// Section
export const Section = styled.section`
  padding-top: ${spacing[3]};
  padding-bottom: ${spacing[3]};
  background-color: ${props =>
    props.hasGreyBG ? colors.grey.light : "transparent"};

  @media screen and (min-width: 500px) {
    padding-top: ${spacing[4]};
    padding-bottom: ${spacing[4]};
  }
`

// PropTypes
Section.propTypes = {
  hasGreyBG: PropTypes.bool,
}
