// Module Imports
import { createGlobalStyle, css } from "styled-components"
import StyledNormalize from "styled-normalize"
// Custom Imports
import { fontCSS, baseFontSize } from "./typo"
import { colors } from "./colors"

const globalCSS = css`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  body {
    ${fontCSS.default};
    font-size: ${baseFontSize};
    line-height: 1.25;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul {
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ul.clean {
    list-style-type: none;
  }

  // Color Classes
  .color-primary-semidark {
    color: ${colors.primary.semidark};
  }
`

export const GlobalStyles = createGlobalStyle`
  ${StyledNormalize};
  ${globalCSS};
`
