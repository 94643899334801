// Module Imports
import styled from "styled-components"
// Custom Imports
import { spacing } from "../designsystem"

// Container
export const Container = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  padding-left: ${spacing[2]};
  padding-right: ${spacing[2]};
`
