import { css } from "styled-components"

export const fontCSS = {
  default: css`
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-weight: 300;
  `,
  bold: css`
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-weight: 700;
  `,
}

export const baseFontSize = "16px"

export const fontSizesCSS = {
  small: css`
    font-size: 0.875rem;
  `,
  default: css`
    font-size: ${baseFontSize};
  `,
  headline4: css`
    font-size: 1.25rem;
  `,
  headline3: css`
    font-size: 1.75rem;
  `,
  headline2: css`
    font-size: 2.25rem;
  `,
  headline1: css`
    font-size: 2.75rem;
  `,
}

export const textElementsCSS = {
  small: css`
    ${fontCSS.default};
    ${fontSizesCSS.small};
  `,
  text: css`
    ${fontCSS.default};
    ${fontSizesCSS.default};
  `,
  bold: css`
    ${fontCSS.bold};
    ${fontSizesCSS.default};
  `,
  headline4: css`
    ${fontCSS.bold};
    ${fontSizesCSS.headline4};
  `,
  headline3: css`
    ${fontCSS.bold};
    ${fontSizesCSS.headline3};
  `,
  headline2: css`
    ${fontCSS.bold};
    ${fontSizesCSS.headline2};
  `,
  headline1: css`
    ${fontCSS.bold};
    ${fontSizesCSS.headline1};
    letter-spacing: 0.05em;
    text-transform: uppercase;
  `,
}
