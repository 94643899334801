// Module Imports
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
// Custom Imports
import SEO from "./seo"
import Nav from "./nav"
import Footer from "../modules/footer"
import { GlobalStyles, colors } from "../designsystem"

// Data Imports
import { dataMenus } from "../data"

// Layout Component
const Layout = ({ children, title, description, dontRenderMainMenu }) => (
  <>
    <SEO title={title} description={description} />
    <GlobalStyles />
    <StyledSkipToContentLink href="#content">
      skip to main content
    </StyledSkipToContentLink>
    <Nav menu={dataMenus.mainMenu} dontRenderMainMenu={dontRenderMainMenu} />
    <main id="content" tabIndex="-1">
      {children}
    </main>
    <Footer />
  </>
)

// SkipToContent Styling
const StyledSkipToContentLink = styled.a`
  padding: 1em;
  position: absolute;
  top: -40px;
  left: 1em;
  z-index: 1000;
  color: ${colors.defaults.white};
  &:focus {
    position: absolute;
    top: 0;
    position: absolute;
    top: 0;
    background: ${colors.grey.dark};
    outline-color: ${colors.primary.normal};
  }
`

// PropTypes
Layout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  dontRenderMainMenu: PropTypes.bool,
}
export default Layout
