import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const dataPartners = [
  {
    title: "Sparkasse",
    Image: (
      <StaticImage
        className="parnter__logo"
        src="../images/sparkasse.png"
        alt="Sparkasse"
      />
    ),
    link: "https://www.sparkasse-saarbruecken.de/",
  },
  {
    title: "Stadtwerke Saarbrücken",
    Image: (
      <StaticImage
        className="parnter__logo"
        src="../images/stadtwerke-saarbruecken.png"
        alt="Stadtwerke Saarbrücken"
      />
    ),
    link: "https://www.saarbruecker-stadtwerke.de/",
  },
  {
    title: "Handwerkskammer des Saarlandes",
    Image: (
      <StaticImage
        className="parnter__logo"
        src="../images/hwk.png"
        alt="Handwerkskammer des Saarlandes"
      />
    ),
    link: "https://www.hwk-saarland.de/",
  },
  {
    title: "Energie SaarLorLux",
    Image: (
      <StaticImage
        className="parnter__logo"
        src="../images/esll.png"
        alt="Energie SaarLorLux"
      />
    ),
    link: "https://www.energie-saarlorlux.com/",
  },
  {
    title: "Hager",
    Image: (
      <StaticImage
        className="parnter__logo"
        src="../images/hager.png"
        alt="Hager"
      />
    ),
    link: "https://www.hager.de/",
  },
  {
    title: "Holzhauer KG",
    Image: (
      <StaticImage
        className="parnter__logo"
        src="../images/holzhauer.png"
        alt="Holzhauer KG"
      />
    ),
    link:
      "https://www.gc-gruppe.de/de/unternehmen/holzhauer-kg/locations/niederlassung-saarbruecken",
  },
  {
    title: "IKK Südwest",
    Image: (
      <StaticImage
        className="parnter__logo"
        src="../images/ikk-suedwest.png"
        alt="IKK Südwest"
      />
    ),
    link: "https://www.ikk-suedwest.de/",
  },
  {
    title: "Sata",
    Image: (
      <StaticImage
        className="parnter__logo"
        src="../images/sata.png"
        alt="Sata"
      />
    ),
    link: "https://www.sata.com/",
  },
  {
    title: "Signal Iduna",
    Image: (
      <StaticImage
        className="parnter__logo"
        src="../images/signal-iduna.png"
        alt="Signal Iduna"
      />
    ),
    link: "https://www.signal-iduna.de/",
  },
  {
    title: "Stiftung Saarländisches Handwerk",
    Image: (
      <StaticImage
        className="parnter__logo"
        src="../images/stiftung-logo.png"
        alt="Stiftung Saarländisches Handwerk"
      />
    ),
    link: "https://www.stiftung-saarlaendisches-handwerk.de/",
  },
  {
    title: "Komplet",
    Image: (
      <StaticImage
        className="parnter__logo"
        src="../images/komplet-logo.svg"
        alt="Komplet"
      />
    ),
    link: "https://de.komplet.com/",
  },
  {
    title: "Elektro Seiwert GmbH",
    Image: (
      <StaticImage
        className="parnter__logo"
        src="../images/elektro-seiwert.png"
        alt="Elektro Seiwert GmbH"
      />
    ),
    link: "https://www.elektro-seiwert.de/",
  },
]
