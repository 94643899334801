// Module Imports
import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
// Custom Imports

// Component
const SEO = ({ title, description }) => (
  <Helmet titleTemplate="%s | Förderverein MTS">
    <html lang="de" />
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta charSet="utf-8" />
  </Helmet>
)

// PropTypes
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

// Export SEO Component
export default SEO
