import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const dataExecutives = [
  {
    Image: (
      <StaticImage
        src="../images/vorstand/cornelia-hoffmann-bethscheider.jpg"
        alt="Cornelia Hoffmann-Bethscheider"
        className="executive__image"
      />
    ),
    name: "Cornelia Hoffmann-Bethscheider",
    position: "Vorsitzende",
    email: "foerderverein@hwk-saarland.de",
  },
  {
    Image: (
      <StaticImage
        src="../images/vorstand/jens-schmitt.jpg"
        alt="Jens Schmitt"
        className="executive__image"
      />
    ),
    name: "Jens Schmitt",
    position: "Stellv. Vorsitzender",
    email: "foerderverein@hwk-saarland.de",
  },
  {
    Image: (
      <StaticImage
        src="../images/vorstand/tobias-schmitt.jpg"
        alt="Tobias Schmitt"
        className="executive__image"
      />
    ),
    name: "Tobias Schmitt",
    position: "Geschäftsführer",
    email: "foerderverein@hwk-saarland.de",
  },
  {
    Image: (
      <StaticImage
        src="../images/vorstand/schieler-klemm.jpg"
        alt="Stefan Schieler-Klemm"
        className="executive__image"
      />
    ),
    name: "Stefan Schieler-Klemm",
    position: "Schatzmeister",
    email: "foerderverein@hwk-saarland.de",
  },
  {
    Image: (
      <StaticImage
        src="../images/vorstand/jutta-henzmann.jpg"
        alt="Jutta Henzmann-Volz"
        className="executive__image"
      />
    ),
    name: "Jutta Henzmann-Volz",
    position: "Beisitzerin",
    email: "foerderverein@hwk-saarland.de",
  },
  {
    Image: (
      <StaticImage
        src="../images/vorstand/bernd-wegner.jpg"
        alt="Bernd Wegner"
        className="executive__image"
      />
    ),
    name: "Bernd Wegner",
    position: "Beisitzer",
    email: "foerderverein@hwk-saarland.de",
  },
  {
    Image: (
      <StaticImage
        src="../images/vorstand/guenter-zimmermann.jpg"
        alt="Günter Zimmermann"
        className="executive__image"
      />
    ),
    name: "Günter Zimmermann",
    position: "Beisitzer",
    email: "foerderverein@hwk-saarland.de",
  },
  {
    Image: (
      <StaticImage
        src="../images/vorstand/roland-spengler.jpg"
        alt="Roland Spengler"
        className="executive__image"
      />
    ),
    name: "Roland Spengler",
    position: "Beisitzer",
    email: "foerderverein@hwk-saarland.de",
  },
  {
    Image: (
      <StaticImage
        src="../images/vorstand/nicole-wilhelm.jpg"
        alt="Nicole Wilhelm"
        className="executive__image"
      />
    ),
    name: "Nicole Wilhelm",
    position: "Beisitzerin",
    email: "foerderverein@hwk-saarland.de",
  },
]
