export const dataContact = {
  mapEmbedPath:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2605.191104478641!2d6.9812625159845165!3d49.234865879326264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4795b4001100d8bf%3A0x595f8456a4834768!2sHandwerkskammer%20des%20Saarlandes!5e0!3m2!1sde!2sde!4v1575307651502!5m2!1sde!2sde",
  title:
    "Förderverein der Meister- und Techniker aus- und -fortbildung im saarländischen Handwerk e.V.",
  street: "Hohenzollernstraße 47 – 49",
  zipAndCity: "66117 Saarbrücken",
  phone: "+49 (0) 681 5809 228",
  email: "foerderverein@hwk-saarland.de",
  downloads: [
    {
      title: "Flyer",
      filePath: "/flyer2024.pdf",
    },
    {
      title: "SEPA Beitritt",
      filePath: "/sepa_beitritt.pdf",
    },
    {
      title: "Satzung",
      filePath: "/satzung.pdf",
    },
  ],
}
