// Module Imports
import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
// Custom Imports
import { Container } from "../layout"
import { spacing, colors } from "../designsystem"
import { Bold } from "../elements"
// Data Imports
import { dataMenus } from "../data"

// Component
const Footer = ({ className }) => {
  const currentYear = new Date().getFullYear()
  return (
  <footer className={className}>
    <Container as="article">
      <Bold>&copy; {currentYear} Förderverein MTS</Bold>
      <ul className="clean">
        {dataMenus.footerMenu.map((item, key) => (
          <li key={key}>
            <Link to={item.href}>{item.title}</Link>
          </li>
        ))}
      </ul>
    </Container>
  </footer>
)

}
// PropTypes
Footer.propTypes = {
  className: PropTypes.string,
}

// Export default as styled component
export default styled(Footer)`
  padding: ${spacing[3]} 0;
  background-color: ${colors.primary.base};
  color: ${colors.defaults.white};

  ul {
    margin-top: ${spacing[2]};
  }

  @media screen and (min-width: 700px) {
    ul {
      margin-top: 0;
    }
    ${Container} {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    li {
      display: inline-block;
      &:not(:last-child) {
        margin-right: ${spacing[2]};
      }
    }
  }
`
