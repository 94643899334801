// Module Imports
import styled from "styled-components"
// Custom Imports
import { textElementsCSS } from "../designsystem"

// Small
export const Small = styled.small`
  ${textElementsCSS.small}
`

export const Text = styled.p`
  ${textElementsCSS.text}
`

export const Bold = styled.strong`
  ${textElementsCSS.bold}
`

export const Headline4 = styled.h4`
  ${textElementsCSS.headline4}
`

export const Headline3 = styled.h3`
  ${textElementsCSS.headline3}
`

export const Headline2 = styled.h2`
  ${textElementsCSS.headline2}
`

export const Headline1 = styled.h1`
  ${textElementsCSS.headline1}
`
