export const dataTheClub = {
  headline: "Der Verein MTS",
  introTextHTML:
    "Herzlich Willkommen beim <strong>“Förderverein der Meister- und Techniker aus- und -fortbildung im saarländischen Handwerk e.V.” </strong>! Was wir machen? Genau das, was unser Name sagt.",
  servicesList: [
    "Finanzielle Unterstützung bei Lehr- und Studienfahrten",
    "Grenzüberschreitende Kontakte zur französischen und luxemburgischen Wirtschaft zu pflegen",
    "Prämierung der besten Meisterstücke und Technikerprüfungen",
    "Unterstützung des Marketings der Meister- und Technikeraus- und -fortbildung",
    "Ideelle Unterstützung und Lobbyarbeit",
    "Kontaktpflege zum Handwerk und der Wirtschaft",
    "Organisation von Ausstellungen",
  ],
}
