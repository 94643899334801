export const colors = {
  primary: {
    light: "#D6ECFF",
    semilight: "#76B8F4",
    base: "#1D77C9",
    semidark: "#03437C",
    dark: "#002342",
  },
  secondary: {
    light: "#FFF1E5",
    semilight: "#FFAD6B",
    base: "#FE7F18",
    semidark: "#FFAD6B",
    dark: "#330E00",
  },
  grey: {
    light: "#FCFCFD",
    semilight: "#DADADD",
    base: "#BDBDC3",
    semidark: "#61626B",
    dark: "#18181B",
  },
  defaults: {
    black: "#000",
    white: "#FFF",
  },
}
