export const dataMenus = {
  mainMenu: [
    {
      title: "Der Verein",
      scrollTo: "club",
    },
    {
      title: "Der Vorstand",
      scrollTo: "executives",
    },
    {
      title: "Kontakt & Downloads",
      scrollTo: "contact",
    },
    {
      title: "Unsere Partner",
      scrollTo: "partners",
    },
  ],
  footerMenu: [
    {
      title: "Impressum & Datenschutz",
      href: "/impressum",
    },
  ],
}
